const temperatureControlConfig = {
  min: 2700,
  max: 6500,
  changeStep: 100,
};

export function normalizeTemperatureValue(temperature: number): number {
  const wholeValue = Math.floor(temperature);
  const step = temperatureControlConfig.changeStep;
  const closestValueBelow = Math.floor(wholeValue / step) * step;
  const closestValueAbove = closestValueBelow + step;
  return (closestValueAbove - wholeValue) < (wholeValue - closestValueBelow)
    ? closestValueAbove
    : closestValueBelow;
}
