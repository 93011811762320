








import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';

import { convertRGBWWToRGB } from '@/helpers/ColorCalculations';
import { ColorWithWhite } from '@/interfaces/lightModes/ColorWithWhite';

export default Vue.extend({
  name: 'CustomColorIcon',
  props: {
    color: {
      type: Object as () => ColorWithWhite,
      required: true,
    },
    isColorTemperature: {
      type: Boolean,
    },
  },
  computed: {
    washedColor(): { r: number; g: number; b: number; alpha: number } {
      if (isEmpty(this.color)) {
        return { r: 255, g: 255, b: 255, alpha: 0.8 };
      }
      if (this.isColorTemperature) {
        return {
          r: this.color.red,
          g: this.color.green,
          b: this.color.blue,
          alpha: this.color.alpha,
        };
      }
      const washedRGB = convertRGBWWToRGB({
        r: this.color.red,
        g: this.color.green,
        b: this.color.blue,
        ww: this.color.ww,
      });
      return {
        ...washedRGB,
        alpha: this.color.alpha,
      };
    },
  },
});
