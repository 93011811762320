import gql from "graphql-tag";

export namespace LightWithCurrentState {

  export const fragment = gql`
    fragment lightFields on BuildingLight {
      id
      name
      currentState {
        state
        dimming
        speed
        ratio
        temperature
        r
        g
        b
        cw
        ww
        sceneId
      }
    }
  `;

  export interface Definition {
    id: string;
    name: string;
    currentState: {
      state: boolean;
      dimming?: number;
      speed?: number;
      ratio?: number;
      temperature?: number;
      r?: number;
      g?: number;
      b?: number;
      cw?: number;
      ww?: number;
      sceneId?: number;
    };
  }

}
