export const scenes: {
  [id: number]: {
    name: string;
    readableName: string;
    colorTemperature?: number;
    wheelColor?: string;
  };
} = {
  0: { name: 'color', readableName: 'Custom color' },
  1: { name: 'ocean', readableName: 'Ocean', wheelColor: '#92CFD4' },
  2: { name: 'romance', readableName: 'Romance', wheelColor: '#7B79B2' },
  3: { name: 'sunset', readableName: 'Sunset', wheelColor: '#FEB63F' },
  4: { name: 'party', readableName: 'Party', wheelColor: '#EB56A5' },
  5: { name: 'fireplace', readableName: 'Fireplace', wheelColor: '#E4AA08' },
  6: { name: 'cozy', readableName: 'Cozy', wheelColor: '#FCE383' },
  7: { name: 'forest', readableName: 'Forest', wheelColor: '#C5E053' },
  8: { name: 'pastelColors', readableName: 'Pastel Colors', wheelColor: '#B35EA1' },
  9: { name: 'wakeup', readableName: 'Wake up', wheelColor: '#D8F0FF' },
  10: { name: 'bedtime', readableName: 'Bedtime', wheelColor: '#FADFAF' },
  11: { name: 'warmlight', readableName: 'Warm white', colorTemperature: 2700, wheelColor: '#FEF9CF' },
  12: { name: 'daylight', readableName: 'Daylight', colorTemperature: 4200, wheelColor: '#E3F3FD' },
  13: { name: 'coolLight', readableName: 'Cool white', colorTemperature: 6500, wheelColor: '#C4E8F9' },
  14: { name: 'nightlight', readableName: 'Night light', wheelColor: '#8D8D8D' },
  15: { name: 'focus', readableName: 'Focus', wheelColor: '#E9FBFE' },
  16: { name: 'relax', readableName: 'Relax', wheelColor: '#FFCEFF' },
  17: { name: 'trueColors', readableName: 'True colors', wheelColor: '#FFF8D3' },
  18: { name: 'tvTime', readableName: 'TV Time', wheelColor: '#88C9FF' },
  19: { name: 'plantGrowth', readableName: 'Plant growth', wheelColor: '#FFA6EE' },
  20: { name: 'spring', readableName: 'Spring', wheelColor: '#FFD4E3' },
  21: { name: 'summer', readableName: 'Summer', wheelColor: '#FFD700' },
  22: { name: 'fall', readableName: 'Fall', wheelColor: '#F5A764' },
  23: { name: 'deepdive', readableName: 'Deep dive', wheelColor: '#229BFF' },
  24: { name: 'jungle', readableName: 'Jungle', wheelColor: '#13AE13' },
  25: { name: 'mojito', readableName: 'Mojito', wheelColor: '#7FFC7F' },
  26: { name: 'club', readableName: 'Club', wheelColor: '#F59858' },
  27: { name: 'christmas', readableName: 'Christmas', wheelColor: '#4BC24B' },
  28: { name: 'halloween', readableName: 'Halloween', wheelColor: '#FFA940' },
  29: { name: 'candlelight', readableName: 'Candlelight', wheelColor: '#FDDC0D' },
  30: { name: 'goldenWhite', readableName: 'Golden white', wheelColor: '#FFEB6F' },
  31: { name: 'pulse', readableName: 'Pulse', wheelColor: '#FFE632' },
  32: { name: 'steampunk', readableName: 'Steampunk', wheelColor: '#FDF9CF' },
};
