import gql from "graphql-tag";

export namespace ChangeRoomState {

  export const mutation = gql`
    mutation($areaId: String!, $roomId: String!, $state: PilotingLightStateInput!) {
      changeRoomState(floorId: $areaId, roomId: $roomId, provider: "WiZ", state: $state)
    }
  `;

  export interface VariablesDefinition {
    areaId: string;
    roomId: string;
    state: {
      state?: boolean;
      dimming?: number;
      speed?: number;
      ratio?: number;
      temperature?: number;
      r?: number;
      g?: number;
      b?: number;
      cw?: number;
      ww?: number;
      sceneId?: number;
    };
  }

  export interface ResultDefinition {
    changeRoomState: boolean;
  }

}
