
























































import Vue from 'vue';
import first from 'lodash/first';
import concat from 'lodash/concat';
import some from 'lodash/some';
import sumBy from 'lodash/sumBy';

import MoreButton from '@/components/buttons/MoreButton.vue';
import FavoriteModeButton from '@/components/rooms/FavoriteModeButton.vue';

import { BuildingWithFullLightsInfo } from '@/graphqlRequests/queries/BuildingWithFullRoomInfo';
import { generateKey } from '@/helpers/StringGenerator';
import { RoomWithLights } from '@/interfaces/rooms/RoomWithLights';
import { LightWithCurrentState } from '@/interfaces/lights/LightWithCurrentState';
import { LightStateInput } from '@/interfaces/lights/LightStateInput';
import { ChangeRoomState } from '@/graphqlRequests/mutations/ChangeRoomState';
import {
  FavoriteStateFields,
  FAVORITE_STATE_CUSTOM_COLOR_TYPENAME,
  FAVORITE_STATE_CUSTOM_WHITE_TYPENAME,
  FAVORITE_STATE_MOMENT_TYPENAME,
  FAVORITE_STATE_SCENE_TYPENAME,
  FAVORITE_STATE_STATUS_TYPENAME,
} from '@/graphqlRequests/fragments/FavoriteStateFragment';
import { ApplyRoomScene } from '@/graphqlRequests/mutations/ApplyRoomScene';

export default Vue.extend({
  name: 'RoomControl',
  components: {
    MoreButton,
    FavoriteModeButton,
  },
  data(): {
    buildingTopology: BuildingWithFullLightsInfo.ContentDefinition | undefined;
  } {
    return {
      buildingTopology: undefined,
    };
  },
  apollo: {
    buildingTopology: {
      skip(): boolean {
        return (
          this.$store.state.selectedBuildingId == undefined
          || this.$store.state.selectedAreaId == undefined
        );
      },
      query: BuildingWithFullLightsInfo.query,
      variables(): BuildingWithFullLightsInfo.VariablesDefinition {
        return {
          buildingId: this.$store.state.selectedBuildingId,
          areaIds: [this.$store.state.selectedAreaId],
        };
      },
      watchLoading(isLoading) {
        if (this.buildingTopology == undefined && isLoading) {
          this.$store.commit('addLoadingProcess', this.buildingTopologyLoadingKey);
        } else {
          this.$store.commit('stopLoadingProcess', this.buildingTopologyLoadingKey);
        }
      },
      pollInterval: 60 * 1000,
    },
  },
  computed: {
    buildingTopologyLoadingKey(): string {
      return generateKey();
    },
    roomId(): string | undefined {
      return this.$store.state.selectedRoomId;
    },
    room(): RoomWithLights | undefined {
      const area = this.buildingTopology && first(this.buildingTopology.areas);
      if (area == undefined || this.roomId == undefined) {
        return undefined;
      }
      return area.rooms.find((room) => room.id == this.roomId);
    },
    roomName(): string | undefined {
      return this.room?.name;
    },
    lights(): LightWithCurrentState[] {
      if (this.room == undefined) {
        return [];
      }
      return concat(
        this.room.lights,
        this.room.groups.flatMap((group) => group.lights),
      );
    },
    isOn(): boolean {
      return some(
        this.lights,
        (light) => light.currentState.state,
      );
    },
    brightness(): number {
      const turnedOnLights = this.lights.filter((light) => light.currentState.state && light.currentState.dimming != undefined);
      if (turnedOnLights.length == 0) {
        return 0;
      }
      const rawValue = sumBy(turnedOnLights, (light) => light.currentState.dimming || 0) / turnedOnLights.length;
      return Math.ceil(rawValue);
    },
  },
  methods: {
    async toggleOnOff(newValue: boolean) {
      const loadingKey = generateKey();
      try {
        this.$store.commit('addLoadingProcess', loadingKey);
        await this.updateRoomState({ state: newValue, });
      } catch (e) {
        return;
      } finally {
        this.$store.commit('stopLoadingProcess', loadingKey);
      }
    },
    async updateBrightness(newValue: string) {
      const loadingKey = generateKey();
      try {
        this.$store.commit('addLoadingProcess', loadingKey);
        await this.updateRoomState({ state: true, dimming: Number(newValue), });
      } catch (e) {
        return;
      } finally {
        this.$store.commit('stopLoadingProcess', loadingKey);
      }
    },
    async activateFavorite(mode: FavoriteStateFields.Definition) {
      const loadingKey = generateKey();
      try {
        this.$store.commit('addLoadingProcess', loadingKey);
        switch (mode.__typename) {
        case FAVORITE_STATE_STATUS_TYPENAME:
          await this.updateRoomState({ state: mode.status, });
          break;
        case FAVORITE_STATE_SCENE_TYPENAME:
          await this.updateRoomState({ state: true, sceneId: mode.sceneId, dimming: mode.specificDimming, speed: mode.speed, });
          break;
        case FAVORITE_STATE_CUSTOM_WHITE_TYPENAME:
          await this.updateRoomState({ state: true, temperature: mode.temperature, dimming: mode.dimming, })
          break;
        case FAVORITE_STATE_CUSTOM_COLOR_TYPENAME:
          await this.updateRoomState({ state: true, r: mode.r, g: mode.g, b: mode.b, cw: mode.cw, ww: mode.ww, dimming: mode.dimming, })
          break;
        case FAVORITE_STATE_MOMENT_TYPENAME:
          await this.activateMoment(mode.momentId);
          break;
        default:
          break;
        }
      } catch (e) {
        return;
      } finally {
        this.$store.commit('stopLoadingProcess', loadingKey);
      }
    },
    async updateRoomState(input: LightStateInput) {
      const areaId = this.$store.state.selectedAreaId;
      if (areaId == undefined || this.roomId == undefined) {
        return;
      }
      await this.$apollo.mutate<ChangeRoomState.ResultDefinition, ChangeRoomState.VariablesDefinition>({
        mutation: ChangeRoomState.mutation,
        variables: {
          areaId,
          roomId: this.roomId,
          state: input,
        },
      });
      window.setTimeout(
        () => {
          this.$apollo.queries.buildingTopology.refetch();
        },
        3000,
      );
    },
    async activateMoment(roomSceneId: string) {
      await this.$apollo.mutate<ApplyRoomScene.ResultDefinition, ApplyRoomScene.VariablesDefinition>({
        mutation: ApplyRoomScene.mutation,
        variables: {
          roomSceneId,
        },
      });
      window.setTimeout(
        () => {
          this.$apollo.queries.buildingTopology.refetch();
        },
        3000,
      );
    },
  },
});
