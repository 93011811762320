







































import Vue from 'vue';

import CustomColorIcon from '@/components/lightModes/CustomColorIcon.vue';

import {
  FavoriteStateFields,
  FavoriteStateScene,
  FAVORITE_STATE_CUSTOM_COLOR_TYPENAME,
  FAVORITE_STATE_CUSTOM_WHITE_TYPENAME,
  FAVORITE_STATE_MOMENT_TYPENAME,
  FAVORITE_STATE_SCENE_TYPENAME,
  FAVORITE_STATE_STATUS_TYPENAME,
} from '@/graphqlRequests/fragments/FavoriteStateFragment';
import { scenes } from '@/constants/scenes';
import lightModes from '@/constants/lightModes';
import { ColorWithWhite } from '@/interfaces/lightModes/ColorWithWhite';
import { colorTemperatureToRGB } from '@/helpers/ColorCalculations';
import { normalizeTemperatureValue } from '@/helpers/ColorTemperature';
import { RoomScene } from '@/interfaces/rooms/RoomWithScenes';

export default Vue.extend({
  name: 'FavoriteModeButton',
  components: {
    CustomColorIcon,
  },
  props: {
    favoriteMode: {
      type: Object as () => FavoriteStateFields.Definition,
      required: true,
    },
    scenes: {
      type: Array as () => RoomScene[],
      default: () => [],
    },
  },
  computed: {
    favoriteScene(): FavoriteStateScene | undefined {
      if (this.favoriteMode.__typename !== FAVORITE_STATE_SCENE_TYPENAME) {
        return undefined;
      }
      return this.favoriteMode;
    },
    favoriteTemperature(): ColorWithWhite | undefined {
      if (this.favoriteMode.__typename !== FAVORITE_STATE_CUSTOM_WHITE_TYPENAME) {
        return undefined;
      }
      return colorTemperatureToRGB(this.favoriteMode.temperature);
    },
    temperatureName(): string | undefined {
      if (this.favoriteMode.__typename !== FAVORITE_STATE_CUSTOM_WHITE_TYPENAME) {
        return undefined;
      }
      const temperature = normalizeTemperatureValue(this.favoriteMode.temperature);
      return `~${temperature}K white`;
    },
    favoriteColor(): ColorWithWhite | undefined {
      if (this.favoriteMode.__typename !== FAVORITE_STATE_CUSTOM_COLOR_TYPENAME) {
        return undefined;
      }
      return {
        red: this.favoriteMode.r,
        green: this.favoriteMode.g,
        blue: this.favoriteMode.b,
        ww: this.favoriteMode.ww,
        alpha: 0.8,
      };
    },
    favoriteMomentName(): string | undefined {
      if (this.favoriteMode.__typename !== FAVORITE_STATE_MOMENT_TYPENAME) {
        return undefined;
      }
      const momentId = this.favoriteMode.momentId;
      const scene = this.scenes.find((roomScene) => roomScene.id === momentId);
      if (scene == undefined) {
        return undefined;
      }
      return scene.name;
    },
    favoriteModeOff(): boolean {
      if (this.favoriteMode.__typename !== FAVORITE_STATE_STATUS_TYPENAME) {
        return false;
      }
      return !this.favoriteMode.status;
    },
  },
  methods: {
    imgSrcByScene(scene: FavoriteStateScene): string {
      const sceneName = scenes[scene.sceneId].name;
      return lightModes[sceneName].default.image;
    },
    sceneName(scene: FavoriteStateScene): string {
      return scenes[scene.sceneId].readableName;
    },
  },
});
