import gql from "graphql-tag";

import { LightWithCurrentState } from "@/graphqlRequests/fragments/LightFragment";
import { FavoriteStateFields } from "@/graphqlRequests/fragments/FavoriteStateFragment";

export namespace BuildingWithFullLightsInfo {

  export const query = gql`
    query buildingTopology($buildingId: String!, $areaIds: [String!]) {
      buildingTopology(buildingId: $buildingId) {
        id
        name
        areas: floors(ids: $areaIds) {
          id
          name
          rooms {
            id
            name
            roomScenes {
              id
              name
            }
            favorite1 {
              ...favoriteStateFields
            }
            favorite2 {
              ...favoriteStateFields
            }
            favorite3 {
              ...favoriteStateFields
            }
            favorite4 {
              ...favoriteStateFields
            }
            lights {
              ...lightFields
            }
            groups {
              id
              name
              lights {
                ...lightFields
              }
            }
          }
        }
      }
    }
    ${LightWithCurrentState.fragment}
    ${FavoriteStateFields.fragment}
  `;

  export interface VariablesDefinition {
    buildingId: string;
    areaIds: string[];
  }

  export interface ContentDefinition {
    id: string;
    name: string;
    areas: {
      id: string;
      name: string;
      rooms: {
        id: string;
        name: string;
        roomScenes: {
          id: string;
          name: string;
        }[];
        favorite1: FavoriteStateFields.Definition;
        favorite2: FavoriteStateFields.Definition;
        favorite3: FavoriteStateFields.Definition;
        favorite4: FavoriteStateFields.Definition;
        lights: LightWithCurrentState.Definition[];
        groups: {
          id: string;
          name: string;
          lights: LightWithCurrentState.Definition[];
        }[];
      }[];
    }[];
  }

  export interface ResultDefinition {
    buildingTopology: ContentDefinition;
  }

}
