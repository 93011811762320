export default {
  bedtime: {
    default: {
      image: require('../assets/lightModes/scene_bedtime_big@2x.png'),
      backlight: require('../assets/lightModes/scene_bedtime_backlight@2x.png'),
    },
    tw: {
      image: require('../assets/lightModes/scene_bedtime_tw_big@2x.png'),
      backlight: require('../assets/lightModes/scene_bedtime_tw_backlight@2x.png'),
    },
    dw: {
      image: require('../assets/lightModes/scene_bedtime_dw_big@2x.png'),
      backlight: require('../assets/lightModes/scene_bedtime_dw_backlight@2x.png'),
    },
  },
  christmas: {
    default: {
      image: require('../assets/lightModes/scene_christmas_big@2x.png'),
      backlight: require('../assets/lightModes/scene_christmas_backlight@2x.png'),
    },
  },
  coolLight: {
    default: {
      image: require('../assets/lightModes/scene_cool_white_big@2x.png'),
      backlight: require('../assets/lightModes/scene_cool_white_backlight@2x.png'),
    },
    dw: {
      image: require('../assets/lightModes/scene_cool_white_big@2x.png'),
      backlight: require('../assets/lightModes/scene_white_rc_backlight@2x.png'),
    },
  },
  cozy: {
    default: {
      image: require('../assets/lightModes/scene_cozy_white_big@2x.png'),
      backlight: require('../assets/lightModes/scene_cozy_white_backlight@2x.png'),
    },
  },
  daylight: {
    default: {
      image: require('../assets/lightModes/scene_daylight_big@2x.png'),
      backlight: require('../assets/lightModes/scene_daylight_backlight@2x.png'),
    },
    dw: {
      image: require('../assets/lightModes/scene_daylight_big@2x.png'),
      backlight: require('../assets/lightModes/scene_white_rc_backlight@2x.png'),
    },
  },
  deepdive: {
    default: {
      image: require('../assets/lightModes/scene_deep_dive_big@2x.png'),
      backlight: require('../assets/lightModes/scene_deep_dive_backlight@2x.png'),
    },
  },
  fall: {
    default: {
      image: require('../assets/lightModes/scene_fall_big@2x.png'),
      backlight: require('../assets/lightModes/scene_fall_backlight@2x.png'),
    },
  },
  fireplace: {
    default: {
      image: require('../assets/lightModes/scene_fireplace_big@2x.png'),
      backlight: require('../assets/lightModes/scene_fireplace_backlight@2x.png'),
    },
  },
  focus: {
    default: {
      image: require('../assets/lightModes/scene_focus_big@2x.png'),
      backlight: require('../assets/lightModes/scene_work_or_study_backlight@2x.png'),
    },
  },
  forest: {
    default: {
      image: require('../assets/lightModes/scene_forest_big@2x.png'),
      backlight: require('../assets/lightModes/scene_forest_backlight@2x.png'),
    },
  },
  halloween: {
    default: {
      image: require('../assets/lightModes/scene_halloween_big@2x.png'),
      backlight: require('../assets/lightModes/scene_halloween_backlight@2x.png'),
    },
  },
  club: {
    default: {
      image: require('../assets/lightModes/scene_jazz_big@2x.png'),
      backlight: require('../assets/lightModes/scene_jazz_backlight@2x.png'),
    },
  },
  jungle: {
    default: {
      image: require('../assets/lightModes/scene_jungle_big@2x.png'),
      backlight: require('../assets/lightModes/scene_jungle_backlight@2x.png'),
    },
  },
  mojito: {
    default: {
      image: require('../assets/lightModes/scene_mojito_big@2x.png'),
      backlight: require('../assets/lightModes/scene_mojito_backlight@2x.png'),
    },
  },
  nightlight: {
    default: {
      image: require('../assets/lightModes/scene_night_light_big@2x.png'),
      backlight: require('../assets/lightModes/scene_night_light_backlight@2x.png'),
    },
    dw: {
      image: require('../assets/lightModes/scene_night_light_dw_big@2x.png'),
      backlight: require('../assets/lightModes/scene_night_light_dw_backlight@2x.png'),
    },
  },
  ocean: {
    default: {
      image: require('../assets/lightModes/scene_ocean_big@2x.png'),
      backlight: require('../assets/lightModes/scene_ocean_backlight@2x.png'),
    },
  },
  party: {
    default: {
      image: require('../assets/lightModes/scene_party_big@2x.png'),
      backlight: require('../assets/lightModes/scene_party_backlight@2x.png'),
    },
  },
  pastelColors: {
    default: {
      image: require('../assets/lightModes/scene_pastel_colors_big@2x.png'),
      backlight: require('../assets/lightModes/scene_pastel_colors_backlight@2x.png'),
    },
  },
  plantGrowth: {
    default: {
      image: require('../assets/lightModes/scene_plants_growth_big@2x.png'),
      backlight: require('../assets/lightModes/scene_plants_growth_backlight@2x.png'),
    },
  },
  relax: {
    default: {
      image: require('../assets/lightModes/scene_relax_big@2x.png'),
      backlight: require('../assets/lightModes/scene_relax_backlight@2x.png'),
    },
    tw: {
      image: require('../assets/lightModes/scene_relax_tw_big@2x.png'),
      backlight: require('../assets/lightModes/scene_relax_tw_backlight@2x.png'),
    },
  },
  romance: {
    default: {
      image: require('../assets/lightModes/scene_romance_big@2x.png'),
      backlight: require('../assets/lightModes/scene_romance_backlight@2x.png'),
    },
  },
  spring: {
    default: {
      image: require('../assets/lightModes/scene_spring_big@2x.png'),
      backlight: require('../assets/lightModes/scene_spring_backlight@2x.png'),
    },
  },
  summer: {
    default: {
      image: require('../assets/lightModes/scene_summer_big@2x.png'),
      backlight: require('../assets/lightModes/scene_summer_backlight@2x.png'),
    },
  },
  sunset: {
    default: {
      image: require('../assets/lightModes/scene_sunset_big@2x.png'),
      backlight: require('../assets/lightModes/scene_sunset_backlight@2x.png'),
    },
  },
  trueColors: {
    default: {
      image: require('../assets/lightModes/scene_true_color_big@2x.png'),
      backlight: require('../assets/lightModes/scene_true_color_backlight@2x.png'),
    },
  },
  tvTime: {
    default: {
      image: require('../assets/lightModes/scene_tv_time_big@2x.png'),
      backlight: require('../assets/lightModes/scene_tv_time_backlight@2x.png'),
    },
    tw: {
      image: require('../assets/lightModes/scene_tv_time_tw_big@2x.png'),
      backlight: require('../assets/lightModes/scene_tv_time_tw_backlight@2x.png'),
    },
  },
  wakeup: {
    default: {
      image: require('../assets/lightModes/scene_wakeup_big@2x.png'),
      backlight: require('../assets/lightModes/scene_wakeup_backlight@2x.png'),
    },
    tw: {
      image: require('../assets/lightModes/scene_wakeup_tw_big@2x.png'),
      backlight: require('../assets/lightModes/scene_wakeup_tw_backlight@2x.png'),
    },
    dw: {
      image: require('../assets/lightModes/scene_wakeup_dw_big@2x.png'),
      backlight: require('../assets/lightModes/scene_wakeup_dw_backlight@2x.png'),
    },
  },
  warmlight: {
    default: {
      image: require('../assets/lightModes/scene_warm_white_big@2x.png'),
      backlight: require('../assets/lightModes/scene_warm_white_backlight@2x.png'),
    },
    dw: {
      image: require('../assets/lightModes/scene_warm_white_big@2x.png'),
      backlight: require('../assets/lightModes/scene_white_rc_backlight@2x.png'),
    },
  },
  candlelight: {
    default: {
      image: require('../assets/lightModes/scene_candlelight_big@2x.png'),
      backlight: require('../assets/lightModes/scene_candlelight_backlight@2x.png'),
    },
  },
  goldenWhite: {
    default: {
      image: require('../assets/lightModes/scene_golden_white_big@2x.png'),
      backlight: require('../assets/lightModes/scene_golden_white_backlight@2x.png'),
    },
  },
  pulse: {
    default: {
      image: require('../assets/lightModes/scene_pulse_big@2x.png'),
      backlight: require('../assets/lightModes/scene_pulse_backlight@2x.png'),
    },
  },
  steampunk: {
    default: {
      image: require('../assets/lightModes/scene_steampunk_big@2x.png'),
      backlight: require('../assets/lightModes/scene_steampunk_backlight@2x.png'),
    },
  },
  color: {
    default: {
      image: require('../assets/lightModes/scene_custom_icon@2x.png'),
      backlight: require('../assets/lightModes/scene_custom_backlight@2x.png'),
    },
    dw: {
      image: require('../assets/lightModes/scene_custom_icon@2x.png'),
      backlight: require('../assets/lightModes/scene_white_rc_backlight@2x.png'),
    },
  },
} as { [id: string]: { default: { image: string } } };
