import clamp from 'lodash/clamp';

import { ColorWithWhite } from '@/interfaces/lightModes/ColorWithWhite';

const MAX_WARM_WHITE_VALUE = 140;

export function colorTemperatureToRGB(temperature: number): ColorWithWhite {
  const x1 = temperature;
  const x2 = x1 * x1;
  const x3 = x2 * x1;
  const x4 = x3 * x1;

  const red =
    -1.50965824672e-13 * x4 +
    4.43233876504e-9 * x3 +
    -4.43004698503e-5 * x2 +
    0.165357905355 * x1 +
    53.9818314731;
  const green =
    -1.40531797401e-13 * x4 +
    4.22990856829e-9 * x3 +
    -4.46147648118e-5 * x2 +
    0.188345364391 * x1 +
    -20.099240286;
  const blue =
    -7.53188624496e-14 * x4 +
    2.61549062886e-9 * x3 +
    -3.2935287027e-5 * x2 +
    0.177362852711 * x1 +
    -88.4682950905;

  return {
    red: Math.floor(clamp(red, 0.0, 255.0)),
    green: Math.floor(clamp(green, 0.0, 255.0)),
    blue: Math.floor(clamp(blue, 0.0, 255.0)),
    ww: 0,
    alpha: 1,
  };
}

function RGB2HSV({
  r,
  g,
  b,
}: {
  r: number;
  g: number;
  b: number;
}): { h: number; s: number; v: number } {
  const rPrime = r / 255;
  const gPrime = g / 255;
  const bPrime = b / 255;
  const cMax = Math.max(rPrime, gPrime, bPrime);
  const cMin = Math.min(rPrime, gPrime, bPrime);
  const delta = cMax - cMin;
  let h = 0;
  switch (true) {
    case delta === 0:
      h = 0;
      break;
    case cMax === rPrime:
      h = 60 * (((gPrime - bPrime) / delta) % 6);
      break;
    case cMax === gPrime:
      h = 60 * ((bPrime - rPrime) / delta + 2);
      break;
    case cMax === bPrime:
      h = 60 * ((rPrime - gPrime) / delta + 4);
      break;
  }
  if (h < 0) {
    h += 360;
  }
  const s = cMax === 0 ? 0 : delta / cMax;
  const v = cMax;
  return { h: Math.floor(h), s, v };
}

export function convertRGBWWToRGB({
  r,
  g,
  b,
  ww,
}: {
  r: number;
  g: number;
  b: number;
  ww: number;
}): { r: number; g: number; b: number } {
  const { v } = RGB2HSV({ r, g, b });
  const wwAdjusted = ww * clamp(ww, 0, MAX_WARM_WHITE_VALUE) / MAX_WARM_WHITE_VALUE + (255 - MAX_WARM_WHITE_VALUE);
  const rgb = {
    r: clamp(r + wwAdjusted * (1 - v), 0, 255),
    g: clamp(g + wwAdjusted * (1 - v), 0, 255),
    b: clamp(b + wwAdjusted * (1 - v), 0, 255),
  };
  return rgb;
}
