import gql from 'graphql-tag';

export const FAVORITE_STATE_STATUS_TYPENAME = 'FavoriteStateStatus';
export interface FavoriteStateStatus {
  __typename: typeof FAVORITE_STATE_STATUS_TYPENAME;
  status: boolean;
}

export type FavoriteStateLightMode =
  | FavoriteStateScene
  | FavoriteStateCustomWhite
  | FavoriteStateCustomColor;

export const FAVORITE_STATE_SCENE_TYPENAME = 'FavoriteStateScene';
export interface FavoriteStateScene {
  __typename: typeof FAVORITE_STATE_SCENE_TYPENAME;
  sceneId: number;
  specificDimming?: number;
  speed?: number;
}

export const FAVORITE_STATE_CUSTOM_WHITE_TYPENAME = 'FavoriteStateCustomWhite';
export interface FavoriteStateCustomWhite {
  __typename: typeof FAVORITE_STATE_CUSTOM_WHITE_TYPENAME;
  temperature: number;
  dimming: number;
}

export const FAVORITE_STATE_CUSTOM_COLOR_TYPENAME = 'FavoriteStateCustomColor';
export interface FavoriteStateCustomColor {
  __typename: typeof FAVORITE_STATE_CUSTOM_COLOR_TYPENAME;
  r: number;
  g: number;
  b: number;
  cw: number;
  ww: number;
  dimming: number;
}

export const FAVORITE_STATE_MOMENT_TYPENAME = 'FavoriteStateMoment';
export interface FavoriteStateMoment {
  __typename: typeof FAVORITE_STATE_MOMENT_TYPENAME;
  momentId: string;
}

export namespace FavoriteStateFields {

  export const fragment = gql`
    fragment favoriteStateFields on FavoriteState {
      ... on FavoriteStateStatus {
        status
      }
      ... on FavoriteStateScene {
        sceneId
        specificDimming
        speed
      }
      ... on FavoriteStateCustomWhite {
        temperature
        dimming
      }
      ... on FavoriteStateCustomColor {
        r
        g
        b
        cw
        ww
        dimming
      }
      ... on FavoriteStateMoment {
        momentId
      }
    }
  `;

  export type Definition =
    | FavoriteStateStatus
    | FavoriteStateLightMode
    | FavoriteStateMoment;

}
